.navbar {
    height: var(--nav-size);
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .logo {
    height: 100%;
    display: flex;
    align-items: center;
    place-items: center;
  }
  
  .logo-logo {
    width: calc(var(--nav-size) * 0.8);
  }
  
  .navbar-nav {
    display: flex;
    justify-content: space-around;
    width: var(--navbar-nav-width);
  }
  
  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
  
  .navitem-button {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 0 15px;
    transition: all 0.2s ease-out;
  }
  
  .navitem-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 1px solid transparent;
  }
  
  .navitem-button::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 1px solid transparent;
  }
  
  .navitem-button:hover::before{
    animation: drawborder-top-right 1s linear forwards;
  }
  
  .navitem-button:hover::after{
    animation: drawborder-bottom-left 1s linear forwards;
  }
  
  .navitem-button.active{
    padding-bottom: 60px;
  }

  .navbar-nav > li:nth-child(2) > a:nth-child(1).active {
      padding-bottom: 150px;
  }
  
  
  @keyframes drawborder-top-right {
    0%  {
      width: 0px;
      border-top-color: var(--primary-color);
    }
  
    50% {
      width: 100%;
      height: 0;
      border-top-color: var(--primary-color);
      border-right-color: var(--primary-color) ;
    }
  
    100%{
      width: 100%;
      height: 100%;
      border-top-color: var(--primary-color);
      border-right-color: var(--primary-color) ;
    }
  }
  
  @keyframes drawborder-bottom-left {
    0%  {
      width: 0px;
      border-bottom-color: var(--primary-color);
    }
  
    50% {
      width: 100%;
      height: 0;
      border-bottom-color: var(--primary-color);
      border-left-color: var(--primary-color) ;
    }
  
    100%{
      width: 100%;
      height: 100%;
      border-bottom-color: var(--primary-color);
      border-left-color: var(--primary-color) ;
    }
  }
  
  
  .DropdownMenu {
    position: absolute;
    padding: 0;
    display: flex;
    flex-direction:column;
    width: 100%;
    top: 32px;
    align-items: center;
    overflow: hidden;
    height: 0px;
    transition: all 0.2s ease-out;
  }

  .DropdownMenu.active.DropdownMenu-maze {
      height: 150px;
  }
  
  .DropdownMenu.active{
    height: 60px;
  }
  
  .DropdownMenu-item {
    padding: 5px 0;
    width: 100%;
    transition: all 300ms ease-out;
    cursor: pointer;
    text-align: center;
  }
  
  .DropdownMenu-item:hover {
    background-color: var(--bg-color-accent); 
  }
  