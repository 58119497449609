html {
  box-sizing: border-box;
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  --nav-size: 60px;
  --navbar-nav-width: 450px;
  --primary-color: #ffeb3b;
  --secondary-color: #9e28a7;
  --other-color: #8286d8;
  --bg-color: #334255;
  --bg-color-accent: #495F7B;
  --primary-color-darken: #B6A40A;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

#root {
  min-height: 100vh;
}
@font-face {
  src: url(fonts/PTSansNarrow-Regular.ttf);
  font-family: SansNarrow;
}
* {
  font-family: Roboto;
}

div.MuiPaper-root {
  display: flex;
  flex-direction: column;
}

.Page {
  display: grid;
  flex-grow: 1;
  grid-template-columns: min(5%, 100px) 1fr min(5%, 100px);
}

.game-area {
  grid-column: 2 / span 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.playing-area {
  width: 450px;
  height: 450px;
  display: grid;
  grid-template: repeat(30, 1fr) / repeat(30, 1fr);
  grid-area: 2 / 1 / 3 / 2;
  border: 3px solid var(--secondary-color);
  border-radius: 16px;
}

.start-button {
  grid-area: 1/ 2/ 2 /3;
  margin: 10px 0 10px 0 !important;
}

