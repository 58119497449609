.foodCell {
  background-color: var(--secondary-color);
}

.wallCell {
}

@keyframes animate-wall {
  0% {
    background-color: var(--primary-color);
  }
  50% {
    background-color: var(--primary-color);
    transform: scale(1.2);
  }
  75% {
    background-color: var(--primary-color);
    transform: scale(1.1);
  }

  100% {
    background-color: var(--primary-color);

    transform: scale(1);
  }
}
.snakeCell {
  background-color: var(--other-color);
}

.cell-0-0 {
  border-top-left-radius: 16px;
}

.cell-0-29 {
  border-top-right-radius: 16px;
}

.cell-29-0 {
  border-bottom-left-radius: 16px;
}

.cell-29-29 {
  border-bottom-right-radius: 16px;
}
